

import React, {useState, useEffect, useCallback} from 'react';
import headerMainLogo from '../../Images/autobahn_korea_logo.png';
import sideMenuMore from '../../Images/Etc/sideMenu_more.png';
import sideMenuMoreClose from '../../Images/Etc/sideMenu_moreClose.png';
import * as H from '../../Styles/StyleComponentHan';
//get_session_data_homepage에 대한 설명은 Axios.js 파일을 참고하세요
import {
  get_session_data_homepage, 
  delete_session_data_homepage,
  get_optimum_film_banner
} from "../../Axios";


export function Header() {  

  //메뉴상태에 따라 보여질지 말지를 판단하기 위해 사용
  const [menuStatus, setMenuStatus] = useState(false);
  const [optimumMenuStatus, setOptimumMenuStatus] = useState(false);
  const [constructionPointID, setConstructionPointID] = useState('');
  const [optimumFilmBannerData, setOptimumFilmBannerData] = useState([]);

  // useEffect는 React에서 제공하는 hook인데 
  // hook에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 설명을 해났음
  // useEffect라는 hook은 앱이 처음실행했을 시 딱 한번만 실행할 수 있는 자바스크립트만
  // 담으면 됩니다.
  // 왜 필요하면 react는 useState를 사용하게 되면 랜더링을 하게되는데
  // useState에 대한 설명은  src/Admin/Login/AdminLogin.js 파일에 설명을 해났음
  // 랜더링이라는 것은 다시한번 소스코드를 읽는다는 건데요.
  // 근데 useEffect안에 있는 자바스크립트는 소스코드를 읽지않습니다.
  // 그래서 필요한겁니다. 
  // 랜더링 될때마다 세션체크는 할필요가 없는거잖아요.
  useEffect(() => {
    //백엔드 서버에 세션이 있는지 없는지 여부에 따라 로그인을 해야되는지 말아야되는지 판단하는 함수
    get_session_data_homepage().then((result)=>{
      if(result.construction_point_ID !== '' || result.construction_point_ID !== undefined){
        setConstructionPointID(result.construction_point_ID);
      }
    });
    get_optimum_film_banner().then(result_data=>{
      setOptimumFilmBannerData(result_data);
      //아래 console.log(result_data)주석을 풀어서
      //크롬창에 F12누르면 콘솔에 찍힙니다.
      /* console.log(result_data); */
    });
  },[]);

  useEffect(() => {
    if(menuStatus) {
      lockScroll();
    } else {
      openScroll();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuStatus])

  // 헤더 팝업시 body 스크롤 막기
  const lockScroll = useCallback(() => {
    document.body.addEventListener('touchmove', touchScroll, {passive : false}); 
    document.body.style.overflowY = 'hidden';
  }, []);
  const openScroll = useCallback(() => {
    document.body.removeEventListener('touchmove', touchScroll, {passive : false});
    document.body.style.overflowY = 'scroll';
  }, []);

  const touchScroll = e => e.preventDefault();

  return (
    //여기는 헤더입니다. 일단 크게 div로 잡아났는데
    //볼보에서는 좌측메뉴에서 숨기고 나오고 작업으로 헤더작업을 했는데
    //과장님이 판단하셔서 프론트 작업 부탁합니다~~
    //참고로 export를 선언하면 다른 파일에서 사용이 가능합니다.
    //이게 파일하나하나를 리엑트에서는 컴포넌트라고 하는데
    //즉 다른 컴포넌터에서 헤더파일을 import해서 가져올 수 있습니다.
    //즉 헤더는 모든 메뉴에 들어가기 떄문에 Header라는 명칭으로 컴포넌트를 하나 생성했고
    //다른데에서 import해서 가져와서 쓸겁니다.
    //자세한건 src/Homepage/Section/Main.js를 참고하세요.
  
    <H.headerDiv>
      <H.headerInner>
        <H.headerMainLogo>
          <a href='/'>
            <img src={headerMainLogo} alt="오토반로고" />
          </a>
        </H.headerMainLogo>
        <button 
          /* 메뉴클릭 시 보여줄지 말지 판단 */
          onClick={()=>{setMenuStatus(!menuStatus)}}
        >메뉴</button>
        {/* 메뉴 버튼 클릭 후 사이드메뉴 css에서 display:none; 처리해둠 */}
        <div 
            //메뉴상태에 따라 메뉴를 보여줄지 말지를 판단
            className={menuStatus === true ? 'blackScream': ' visibility_hidden'}
            //클릭 시 메뉴상태를 역으로 변경
          >
        </div>
        <div 
          //메뉴상태에 따라 메뉴를 보여줄지 말지를 판단
          className={menuStatus === true ? 'sideMenu': 'sideMenuOpen'}
          //클릭 시 메뉴상태를 역으로 변경
        >
          <div className={menuStatus === true ? 'sideMenuInner': 'display_none'}>
            <div className='sideMenuTop'>
              <a href='/'>
                <img src={headerMainLogo} alt="오토반로고" />
              </a>
              <button
                /* 닫기 클릭 시 보여줄지 말지 판단 */
                onClick={()=>{setMenuStatus(!menuStatus)}}
              >닫기</button>
            </div>

            <H.gnbMenu>
              <div>
                <div className="sideMenuFlex" onClick={()=>{setOptimumMenuStatus(!optimumMenuStatus)}}>
                  <p>오토반 썬팅필름</p>
                  <img src={optimumMenuStatus ? sideMenuMoreClose:sideMenuMore} alt="열기닫기"></img>
                </div>
                <div className={optimumMenuStatus === true ? 'sideMenuSub': 'display_none'}>
                  <a href='/OptimumFilmType'>오토반 썬팅필름</a>
                  {optimumFilmBannerData.map((element, index) => 
                  <a href={'/OptimumFilmTypeDetail?optimum_film_banner_ID='+element.optimum_film_banner_ID} key={index}>{element.optimum_film_banner_name}</a>
                  )}
                </div>
              </div>
              <div>
                <a href='/OptimumPriceSpec'>가격/스펙</a>
              </div>
              <div>
                <a href='/OCPoint'>시공점 찾기</a>
              </div>
              <div>
                <a href='/NewsEvent'>뉴스 & 이벤트</a>
              </div>
              <div>
                <a href='/Validate'>정품 확인</a>
              </div>
              <div className="gnbMenuIntranet">
                {/* 로그인 버튼은 임시로 잡아둔거고 이후 다른거랑 정리되면 한번에 코드 잡을예정 */}
                {/* 로그아웃 했을 시 출력 */}
                {(constructionPointID === '' || constructionPointID === undefined) && 
                  <div>
                    <a href='/Login'>Intranet</a>
                  </div>
                }
                {/* 로그인 했을 시 출력 */}
                {(constructionPointID !== '' && constructionPointID !== undefined) && 
                <>
                  <div className="gnbMenuIntranetLogout">
                    <a
                      href="/"
                      onClick={()=>{
                        delete_session_data_homepage();
                        setConstructionPointID('');
                      }}
                    >
                      로그아웃
                    </a>
                  </div>
                  <div>
                    <a href='/Guarantee'>보증서 발급</a>
                  </div>
                  <div>
                    <a href='/Notice'>공지사항</a>
                  </div>
                  <div>
                    <a href='/Archive'>자료실</a>
                  </div>
                </>
                }
              </div>
           
              <div className="gnbMenuSns">
                <a href="https://www.facebook.com/KoreaTintercom/" target="blank" title="facebook">
                  <img width="24" height="24" src="https://www.volvocars.com/static/shared/icons/v2/thirdparty-facebook-24.svg#light-primary" role="presentation" alt="" className="_SN-cc _SN-cf _SN-cg _SN-ch"/>
                </a>   
                <a href="https://www.instagram.com/optimum_korea/" target="blank" title="youtube">
                  <img width="24" height="24" src="https://www.volvocars.com/static/shared/icons/v2/thirdparty-instagram-24.svg#light-primary" role="presentation" alt="" className="_SN-cc _SN-cf _SN-cg _SN-ch"/>
                </a> 
                <a href="https://www.youtube.com/user/solargardkorea" target="blank" title="youtube">
                  <img width="24" height="24" src="https://www.volvocars.com/static/shared/icons/v2/thirdparty-youtube-24.svg#light-primary" role="presentation" alt="" className="_SN-cc _SN-cf _SN-cg _SN-ch"/>
                </a>               
              </div>
            </H.gnbMenu>

            
          </div>
        </div>
      </H.headerInner>
    </H.headerDiv>

  );
}

export default Header;
