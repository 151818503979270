import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

/* 홈페이지 영역 */
import HomepageMain from './Homepage/Main/Main';
import OptimumFilmType from './Homepage/OptimumFilm/OptimumFilmType';
import OptimumFilmTypeDetail from './Homepage/OptimumFilm/OptimumFilmTypeDetail';
import OptimumPriceSpec from './Homepage/OptimumFilm/OptimumPriceSpec';
import OCPoint from './Homepage/ConstructionSearch/OCPoint';
import NewsEvent from './Homepage/NewsEvent/NewsEvent';
import NewsEventDetail from './Homepage/NewsEvent/NewsEventDetail';
import Login from './Homepage/Login/Login';
import Guarantee from './Homepage/Guarantee/Guarantee';
import GuaranteeAdd from './Homepage/Guarantee/GuaranteeAdd';
import Notice from './Homepage/Board/Notice';
import NoticeDetail from './Homepage/Board/NoticeDetail';
import Archive from './Homepage/Board/Archive';
import ArchiveDetail from './Homepage/Board/ArchiveDetail';
import Validate from './Homepage/Validate/Validate';

/* 관리자 영역 */
import AdminLogin from './Admin/Login/AdminLogin';
import AdminMain from './Admin/Main/AdminMain';
import AdminRollingBannerManagement from './Admin/HomePageManagement/MainManagement/AdminRollingBannerManagement';
import AdminRollingBannerManagementAdd from './Admin/HomePageManagement/MainManagement/AdminRollingBannerManagementAdd';
import OptimumFilmBannerManagement from './Admin/HomePageManagement/MainManagement/OptimumFilmBannerManagement';
import OptimumFilmBannerManagementAdd from './Admin/HomePageManagement/MainManagement/OptimumFilmBannerManagementAdd';
import AdminOfTypeManagement from './Admin/HomePageManagement/OfManagement/AdminOfTypeManagement';
import AdminOfPriceSpecManagement from './Admin/HomePageManagement/OfManagement/AdminOfPriceSpecManagement';
import AdminOCPointManagement from './Admin/HomePageManagement/CSearchManagement/AdminOCPointManagement';
import AdminOCPointManagementAdd from './Admin/HomePageManagement/CSearchManagement/AdminOCPointManagementAdd';
import NewsEventManagement from './Admin/HomePageManagement/NewsEventManagement/NewsEventManagement';
import NewsEventManagementAdd from './Admin/HomePageManagement/NewsEventManagement/NewsEventManagementAdd';
import OurStoryBannerManagement from './Admin/HomePageManagement/MainManagement/OurStoryBannerManagement';
import GuaranteeManagement from './Admin/GuaranteeManagement/GuaranteeManagement';
import GuaranteeManagementDetail from './Admin/GuaranteeManagement/GuaranteeManagementDetail';
import OptimumFilmTypeManagement from './Admin/GuaranteeManagement/OptimumFilmTypeManagement';
import OptimumFilmTypeManagementAdd from './Admin/GuaranteeManagement/OptimumFilmTypeManagementAdd';
import GuaranteePeriodManagement from './Admin/GuaranteeManagement/GuaranteePeriodManagement';
import GuaranteePeriodManagementAdd from './Admin/GuaranteeManagement/GuaranteePeriodManagementAdd';
import MenuPermissionManagement from './Admin/SystemManagement/MenuPermissionManagement';
import MenuPermissionManagementAdd from './Admin/SystemManagement/MenuPermissionManagementAdd';
import UserManagement from './Admin/SystemManagement/UserManagement';
import UserManagementAdd from './Admin/SystemManagement/UserManagementAdd';
import NoticeManagement from './Admin/BoardManagement/NoticeManagement';
import NoticeManagementAdd from './Admin/BoardManagement/NoticeManagementAdd';
import ArchiveManagement from './Admin/BoardManagement/ArchiveManagement';
import ArchiveManagementAdd from './Admin/BoardManagement/ArchiveManagementAdd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      {/* 홈페이지 영역*/}
      {/* 경로를 /라고 치면 HomepageMain으로 이동함 */}
      <Route path="/" element={<HomepageMain />} />{/* 홈페이지 메인화면 */}
      <Route path="/OptimumFilmType" element={<OptimumFilmType />} />{/* 홈페이지 오토반 필름 종류화면 */}
      <Route path="/OptimumFilmTypeDetail" element={<OptimumFilmTypeDetail />} />{/* 홈페이지 오토반 필름 상세화면 */}
      <Route path="/OptimumPriceSpec" element={<OptimumPriceSpec />} />{/* 홈페이지 오토반 필름 가격표 스펙화면 */}
      <Route path="/OCPoint" element={<OCPoint />} />{/* 홈페이지 오토반 시공점화면 */}
      <Route path="/NewsEvent" element={<NewsEvent />} />{/* 홈페이지 뉴스 & 이벤트 화면 */}
      <Route path="/NewsEventDetail" element={<NewsEventDetail />} />{/* 홈페이지 뉴스 & 이벤트 상세화면 */}
      <Route path="/Login" element={<Login />} />{/* 홈페이지 로그인 화면 */}
      <Route path="/Guarantee" element={<Guarantee />} />{/* 홈페이지 보증서 화면 */}
      <Route path="/GuaranteeAdd" element={<GuaranteeAdd />} />{/* 홈페이지 보증서 등록/수정 화면 */}
      <Route path="/Notice" element={<Notice />} />{/* 공지사황 화면 */}
      <Route path="/NoticeDetail" element={<NoticeDetail />} />{/* 공지사황 상세화면 */}
      <Route path="/Archive" element={<Archive />} />{/* 자료실 화면 */}
      <Route path="/ArchiveDetail" element={<ArchiveDetail />} />{/* 자료실 상세화면 */}
      <Route path="/Validate" element={<Validate />} />{/* 정품확인서 화면 */}

      {/* 관리자페이지 영역*/}
      {/* 경로를 /AdminLogin이라고 치면 AdminLogin으로 이동함 */}
      <Route path="/AdminLogin" element={<AdminLogin />} /> {/* 관리자페이지 로그인화면 */}
      <Route path="/AdminMain" element={<AdminMain />} /> {/* 관리자페이지 메인화면 */}
      <Route path="/AdminRollingBannerManagement" element={<AdminRollingBannerManagement />} /> {/* 관리자페이지 롤링 배너관리 */}
      <Route path="/AdminRollingBannerManagementAdd" element={<AdminRollingBannerManagementAdd />} /> {/* 관리자페이지 롤링 배너등록/수정 메뉴 */}
      <Route path="/OptimumFilmBannerManagement" element={<OptimumFilmBannerManagement />} /> {/* 관리자페이지 오토반 썬팅필름 배너 */}
      <Route path="/OptimumFilmBannerManagementAdd" element={<OptimumFilmBannerManagementAdd />} /> {/* 관리자페이지 오토반 썬팅필름 배너등록/수정 메뉴 */}
      <Route path="/AdminOfTypeManagement" element={<AdminOfTypeManagement />} /> {/* 관리자페이지 옵테멈윈도우 필름 종류관리 */}
      <Route path="/AdminOfPriceSpecManagement" element={<AdminOfPriceSpecManagement />} /> {/* 관리자페이지 옵테멈윈도우 필름 가격표 스펙 관리 */}
      <Route path="/AdminOCPointManagement" element={<AdminOCPointManagement />} /> {/* 관리자페이지 오토반 시공점 관리 */}
      <Route path="/AdminOCPointManagementAdd" element={<AdminOCPointManagementAdd />} /> {/* 관리자페이지 오토반 등록/수정메뉴 관리 */}
      <Route path="/NewsEventManagement" element={<NewsEventManagement />} /> {/* 관리자페이지 뉴스 & 이벤트 관리 */}
      <Route path="/NewsEventManagementAdd" element={<NewsEventManagementAdd />} /> {/* 관리자페이지 뉴스 & 이벤트 등륵/수정 관리 */}
      <Route path="/OurStoryBannerManagement" element={<OurStoryBannerManagement />} />{/* 관리자페이지 대형 이미지 배너 관리 */}
      <Route path="/GuaranteeManagement" element={<GuaranteeManagement />} /> {/* 관리자페이지 보증서 관리 */}
      <Route path="/GuaranteeManagementDetail" element={<GuaranteeManagementDetail />} /> {/* 관리자페이지 보증서 수정 / 상세보기 */}
      <Route path="/OptimumFilmTypeManagement" element={<OptimumFilmTypeManagement />} /> {/* 관리자페이지 오토반 썬팅필름 종류 관리 */}
      <Route path="/OptimumFilmTypeManagementAdd" element={<OptimumFilmTypeManagementAdd />} /> {/* 관리자페이지 오토반 썬팅필름 종류 등륵/수정 관리 */}
      <Route path="/GuaranteePeriodManagement" element={<GuaranteePeriodManagement />} /> {/* 관리자페이지 보증기간 관리 */}
      <Route path="/GuaranteePeriodManagementAdd" element={<GuaranteePeriodManagementAdd />} /> {/* 관리자페이지 보증기간 등륵/수정 관리 */}
      <Route path="/MenuPermissionManagement" element={<MenuPermissionManagement />} /> {/* 관리자페이지 메뉴권한 관리 */}
      <Route path="/MenuPermissionManagementAdd" element={<MenuPermissionManagementAdd />} /> {/* 관리자페이지 메뉴권한 등륵/수정 관리 */}
      <Route path="/UserManagement" element={<UserManagement />} /> {/* 관리자페이지 사용자 관리 */}
      <Route path="/UserManagementAdd" element={<UserManagementAdd />} /> {/* 관리자페이지 사용자 등륵/수정 관리 */}
      <Route path="/NoticeManagement" element={<NoticeManagement />} /> {/* 관리자페이지 공지사항 관리 */}
      <Route path="/NoticeManagementAdd" element={<NoticeManagementAdd />} /> {/* 관리자페이지 공지사항 등록/수정 메뉴 */}
      <Route path="/ArchiveManagement" element={<ArchiveManagement />} /> {/* 관리자페이지 자료실 관리 */}
      <Route path="/ArchiveManagementAdd" element={<ArchiveManagementAdd />} /> {/* 관리자페이지 자료실 등록/수정 메뉴 */}

    </Routes>
  </BrowserRouter>
);
